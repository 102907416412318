const lang_text = {
  'zh':[
    '选填',
    '单选',
    "多选",
    "为了确保奖励发放无误，请您务必填写正确id",
    "请您列举您感兴现的其他内容",
    "提交",
    "提交成功",
    "您有未完成的题目 请完成作答",
    "确认",
    "抱歉，地址错误 找不到页面",
    '问卷调查'
  ],
  'en':[
    'optional',
    'radio',
    "multiple choice",
    "To ensure the correctness of reward distribution, please make sure to fill in the correct ID.",
    "Could you please list any other content that you are interested in.",
    "Submit ",
    "Submitted successfully",
    "You have unfinished questions, please complete them.",
    "confirm",
    "Sorry, the address is incorrect and the page cannot be found.",
    "questionnaire investigation"
  ],
  'ja':[
    'オプション',
    '単一選択',
    "複数選択",
    "奨励金の発行に間違いがないように、必ず正しいIDを記入してください",
    "あなたが興味を持っている他の内容を挙げてください",
    "送信",
    "コミット成功",
    "未完成の問題がありましたら、回答を完了してください.",
    "確認",
    "申し訳ありませんが、アドレスエラーでページが見つかりません",
    "アンケート調査"
  ],
  'ko':[
    '선택',
    '라디오',
    "다선",
    "보상 지급이 정확한지 확인하기 위해서, 당신은 반드시 정확한 id를 기입하십시오",
    "당신이 감흥하고 있는 기타 내용을 열거해 주십시오",
    "제출",
    "커밋 성공",
    "당신은 미완성 문제가 있으니, 답안을 완성하십시오.",
    "확인",
    "죄송합니다. 주소 오류로 페이지를 찾을 수 없습니다.",
    "설문 조사"
  ],
  'es':[
    'Selección y llenado',
    'Elecciones individuales',
    "Selección múltiple",
    "Para garantizar que la recompensa se pague correctamente, asegúrese de rellenar el id correcto.",
    "Por favor, enumere otros contenidos que le interesan.",
    "Presentación",
    "Presentación exitosa",
    "Tiene preguntas pendientes, complete la respuesta.",
    "Confirmación",
    "Lo siento, la dirección es incorrecta y no se puede encontrar la página.",
    "Cuestionario"
  ],
  'de':[
    'Optional',
    'Single Choice',
    "Multiple Choice",
    "Um die korrekte Verteilung der Belohnungen zu gew?hrleisten, stellen Sie bitte sicher, dass Sie die richtige ID eingeben",
    "K?nnten Sie bitte andere Inhalte auflisten, an denen Sie interessiert sind",
    "Absenden",
    "Erfolgreich eingereicht",
    "Sie haben noch offene Fragen, füllen Sie diese bitte aus und beantworten Sie sie.",
    "bestätigen",
    "Sorry, die Adresse ist falsch und die Seite kann nicht gefunden werden",
    "Fragebogenumfrage"
  ],
  'fr':[
    'Sélectionnez remplir',
    'Sélection unique',
    "Sélection multiple",
    "Pour vous assurer que les récompenses sont attribuées sans erreur, assurez - vous de remplir le bon identifiant",
    "Veuillez énumérer d'autres choses qui vous intéressent",
    "Soumettre",
    "Soumettre avec succès",
    "Vous avez un sujet inachevé, veuillez compléter la réponse.",
    "Confirmation",
    "Désolé, erreur d'adresse Page introuvable",
    "Enquête par questionnaire"
  ],
}

export default lang_text;