<template>
  <div id="bodyRef" :class='["body_box",isShow?"body_box_noscroll":""]'>
    <div v-if="!is_finish" class="wrap">
      <div v-if="!link_error" class="center_content" :class="isShow?'center_no_scroll':''" >
      <div class="content" :class="isShow?'center_content_no_scroll':''">
        <div class='title_desc'>
          <img  src="../../assets/title_icon.png"/>
          <p class="title">{{ data.form_title }}</p>
        </div>
        <p class="desc">{{ data.form_describe }}</p>
        <div class="option_list">
          <div v-for="(item ,index) in data.form_option_list"  :key=index  class="option_item" :id="item.option_id" :ref="item.option_id">
                <div class="option_title">
                  <div>
                    {{ index+1 }}.{{ item.option_name }}
                    <span class="option_must">({{ item.option_type ==3 ? lang_text[lang][0]: item.option_type == 1 ? lang_text[lang][1] : lang_text[lang][2] }})</span>
                  </div>
                  <img v-if="item.option_image" class="option_title_img" :src="item.option_image"/>
                </div>
                    <div v-if="item.option_type == 1" class="option_radio">
                      <div v-for="(ele,ele_index) in item.form_value_list" :key="ele_index" :class='[newData[index]?.ishasimg? "option_radio_img" :"option_radio_block"]'>
                        <label v-if="ele.value_image" :for="ele.value_id"><img class="option_img" :src=ele.value_image /></label>
                        <div class="option_text">
                            <input class="radio_input" type="radio" :key="ele_index" :name="index" :id="ele.value_id" :value="ele.value_id"  @change="handelRadio(index,$event)"/>
                            <label :class='[newData[index]?.answer == ele.value_id ? "radio_text_active":"","radio_text"]' :for="ele.value_id" >{{ ele.value_name }}</label>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="item.option_type == 2" class="option_checkbox_wrap">
                      <div  class="option_checkbox">
                        <div v-for="(ele,ele_index) in item.form_value_list" :key="ele_index" :class='[newData[index]?.ishasimg?"checkbox_img":"checkbox_block"]'>
                          <label v-if="ele.value_image" :for="ele.value_id"><img class="checkbox_block_img" :src="ele.value_image"/></label> 
                          <div class="checkbox_block_choose">
                            <input class="checkbox_input" type="checkbox" :key="ele_index" :id="ele.value_id" :value="ele.value_id"  @change="handelCheckBox(index,ele_index,ele,$event)"/>

                              <!-- <input class="checkbox_input" type="checkbox" :key="ele_index" :id="ele.value_id"  v-model="newData[index].answer" :value="ele.value_id" @change="handelCheckBox(index,ele_index,ele,$event)"/> -->
                              <label :class ='[newData[index]?.answer.indexOf(ele.value_id.toString()) != -1 ? "checkbox_lable_active":"","checkbox_lable"]' :for="ele.value_id">{{ele.value_name}}</label>
                          </div>
                        </div>
                      </div> 
                      <input v-if="newData.length !=0 && newData[index].is_input" class="checkbox_input"  maxLength="255" :placeholder="lang_text[lang][4]" @change="handelOptionInput(index)" />
                    </div>
                    <div v-else class="suggest_input">
                       <img class="suggest_icon" src="../../assets/text_icon.png" />
                      <textarea class="suggest_text" rows="4" maxLength="500"  @change="(e)=>handelOptionInput(index,e)" ></textarea>
                    </div>
              </div>
        </div>
        <div class="submitBtn" @click="handelSubmit">{{ lang == "" ? "" : lang_text[lang][5] }}</div>
      </div>
      </div>
      <div v-if="isShow" class="warn_dialog">
        <img src="../../assets/wran_tips.png"/>
        <p>{{ link_error?lang_text[lang][9] : lang_text[lang][7] }}</p>
        <div class="sure" @click="handelOK">{{ lang_text[lang][8] }}</div>
      </div>
  </div>

  <div v-else class="finish_wrap">
      <div class="center_content">
        <div class="content">
          <div class="finish_icon">
            <img src="../../assets/finish.png" />
            <p>{{ lang_text[lang][6] }}</p>
          </div>
          <!-- <div class="more_type">
            <div class="more_app">
              <img v-for="(item,index) in Object.keys(appLink)" :key="index" @click="handelTempApp(index)" :src="require('../../assets/'+app_icon[index]+'.png')"/>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import lang_text from "../../data/lang_text"
import CryptoJS from "../../cor"

export default {
  name: "HomeView",
  data(){
    return {
      data:{}, // 页面数据
      newData:[],
      isShow:false,
      appLink:{},
      linkParams:{},// 地址参数
      link_error:false, // 请求连接是否正确
      lang_text:lang_text,
      is_finish:false,
      appLink:{},
      lang_text:lang_text,
      app_icon:['facebook','twitter','youtube','instagram','reddit'],
      lang:'en',
      isCheck: [39,40,42],
      secret_key: "QqzLaefKFHcr69dEYkZsRBbUPwGoXJj4",
      lanList: ["en", "zh", "ja", "ko", "es", "de", "fr"],
      curLan: "en"
    }
  },
  mounted(){
    this.curLan = navigator.language.split('-')[0];
    this.getLinkParams();
  },
  methods:{
    // 请求页面数据
    surveyData(){
       axios({
        method:'post',
        url:"https://survey.letuinet.com/api/survey/data",
        // data:{form_key:'zck4kNuS',uid:'454'},
        data: {data: this.encrypt({form_key:this.linkParams.wj,uid:this.linkParams.uid})},
        headers:{
          "Content-Type":'application/json',
        'formkey': this.linkParams.wj,
          'lang':this.linkParams.lang == null ? this.lanList.indexOf(this.curLan)>=0?this.curLan:"en" :this.linkParams.lang,
          'RequiredId': this.randomString(),
        }
      }).then((res)=>{
        if(res.data.data != null){
          this.appLink = res.data.data.share_url
          this.lang = res.data.data.lang;
          if(res.data.data.is_submit != 1){
            this.data = res.data.data.data;
            // 整理表单数据
            let new_data_arr = [];
            res.data.data.data.form_option_list?.map((item)=>{
              if(item.option_type === 1){ // 单选题
                new_data_arr.push({option_id:item.option_id,answer:undefined,answer_describe:"", type:1,ishasimg:item.form_value_list.some((ele)=>ele.value_image !='')});
              }else if(item.option_type === 2){ // 多选题
                new_data_arr.push({option_id:item.option_id,answer:[],answer_describe:"", type:2,select_num: item.select_num,is_input:false,ishasimg:item.form_value_list.some((ele)=>ele.value_image !='')});
              }else{ // 文本输入
                new_data_arr.push({option_id:item.option_id,answer:"",answer_describe:"", type:3});
              }
            })
            this.newData = new_data_arr
          }else{
            this.is_finish = true
            // this.$router.push({path:'/surveyRes',query:{lang:this.lang,link:JSON.stringify(this.appLink)}})
          }
        }else{
          this.link_error = true
          this.isShow = true
        }
      })
    },
    
    // 
    getLinkParams(){
      let p=window.location.href.split('?')[1]
      let params=new URLSearchParams(p)
      this.linkParams = {
        wj:params.get('wj'),
        uid:params.get('uid'),
        lang:params.get('lang')
      }
      this.link_error = params.get('wj') == null ? true : false;
      if(!this.link_error){
        this.surveyData();
      }else{
        this.isShow = true
      }
      document.getElementsByTagName('title')[0].innerHTML = this.lang_text[this.lang][10]
      this.lang = this.lanList.indexOf(this.curLan)>=0?this.curLan:"en"
    },

    // 单选
    handelRadio(index,e){
      let new_data_arr = [].concat(this.newData);
      new_data_arr[index].answer = e.target.value;
      this.newData = new_data_arr
    },

    // 多选
    handelCheckBox(index,ele_index,item,e){
      let new_data_arr = [].concat(this.newData);
      if(new_data_arr[index].answer.length === 0){
        new_data_arr[index].answer.push(e.target.value);
        if(item.is_input == 1){
          new_data_arr[index].is_input = true
        }
      }else{
        let options_item_index = new_data_arr[index].answer.findIndex((i)=> i === e.target.value);
        // 重复点击按钮
        if(options_item_index !== -1 ){
          new_data_arr[index].answer.splice(options_item_index,1)
          if(item.is_input == 1){
            new_data_arr[index].is_input = false
          }
        }else{
          // 添加新选项
          if(new_data_arr[index].answer.length < 3){
            new_data_arr[index].answer.push(e.target.value); // 添加新选项
            e.target.checked = true;
            if(item.is_input == 1 ){
              new_data_arr[index].is_input = true
            }
          }else{ // 点击无效
            e.target.checked = false
            if(item.is_input == 1 ){
              new_data_arr[index].is_input = false
            }
          }
        }

      }

      this.newData = new_data_arr;
    },
   
    // 多选输入框
    handelOptionInput(index,e){
      let new_data_arr= [].concat(this.newData);
      new_data_arr[index].answer_describe = e.target.value;
      this.newData = new_data_arr;
    },

        // 表单提交
    handelSubmit(){
      let req_flag = false;
      for(let i = 0;i<this.newData.length;i++){
        if(this.newData[i].answer != undefined){
          this.newData[i].answer = this.newData[i].answer+"";
        }
        if(this.newData[i].type != 3 && this.newData[i].answer == undefined){
          this.isShow = true
          req_flag = true;
          return;
        }
      }

      let data = {
        form_key:this.linkParams?.wj,
        required_id:(new Date().getTime() + Math.ceil(Math.random()*100000)).toString(),
        uid:this.linkParams?.uid,
        answer_list:this.newData
      }

      if(!req_flag){
        axios({
          method:'post',
          url:"https://survey.letuinet.com/api/survey/submit",
          data:{data: this.encrypt(data)},
          headers:{
            "Content-Type":'application/json',
          'formkey': this.linkParams.wj,
          'lang':this.linkParams.lang == null ? this.lanList.indexOf(this.curLan)>=0?this.curLan:"en" :this.linkParams.lang,
          "RequiredId": this.randomString(),
          }
         }).then((res)=>{
          this.is_finish = true;
          // this.$router.push({path:'/surveyRes',query:{lang:this.lang,link:JSON.stringify(this.appLink)}})
          // history.push('/finishPage',{lang:this.lang,link:this.appLink});
        })
    }},

      // 弹框确认
    handelOK(){
      this.isShow = false;
      for(let i = 0;i<this.newData.length;i++){
          if(this.newData[i].type != 3 && this.newData[i].answer == undefined){
            let id = this.newData[i].option_id;
            
            let obj_item = this.$refs[`${id}`][0];
            if (!obj_item) return;
            document.getElementById('bodyRef')?.scrollTo({
              top: obj_item.offsetTop-20,
              behavior: "smooth"
            });
            return;
          }
        }
      },
      handelTempApp(index){
        window.location.href = index == 0 ? this.appLink.fb : index == 1 ? this.appLink.tw:index == 2 ? this.appLink.yo : index == 3 ? this.appLink.in:this.appLink.re
      },

      //aes加密
encrypt(word){
  const key = CryptoJS.enc.Utf8.parse(this.secret_key);
  // const iv = CryptoJS.enc.Utf8.parse(secret_key);

  //字符串
  // const srcs = CryptoJS.enc.Utf8.parse(word);
  //对象
  const data = JSON.stringify(word);
  const srcs = CryptoJS.enc.Utf8.parse(data);
  let encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv: key,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return encrypted.ciphertext.toString();
},
randomString(){
  let t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
    e = t.length,
    str = "";
  for (let n = 0; n < 5; n++)
    str += t.charAt(Math.floor(Math.random() * e));
  return str
}
      
     },
    }
</script>

<style scoped lang="less">
.wrap{
    min-height: 100vh;
    max-width: 750px;
    margin: 0 auto;
    background-color: #2FB8FF;
    background-image: url("../../assets/header.png"), url("../../assets/footer.png");
    background-position: center top,center bottom;
    background-repeat:no-repeat,no-repeat;
    background-size: 100%, 100%;
    overflow: scroll;
    .center_content{
        .content{
            box-sizing: border-box;
            margin: 237px auto 307px auto;
            width: 663px;
            border-radius: 24px;
            border:  1px solid black;
            background-color: #fff;
            padding: 40px 44px 87px 44px;
            .title_desc{
              display: flex;
              justify-content: center;
              align-items: start;
              margin-bottom: 24px;
              img{
                  width: 24px;
                  height: 37px;
                  margin-right: 19px;
                  margin-top: 10px;
              }
              .title{
                  display: inline-block;
                  font-size: 39px;
                  margin: 0;
                  font-weight: bold;
              }
            }
            .desc{
              color: #2FB8FF;
              font-size: 20px;
              font-weight: 600;
              padding-bottom: 39px;
              margin-bottom: 41px;
              border-bottom:  0.5px solid #D5D5D5;
             }
            .option_list{
              .option_item{
                   border-bottom: 1px solid #D5D5D5;
                   margin-bottom: 15px;
                   padding-bottom: 39px;
                  .option_title{
                      font-size: 26px;
                      font-weight: bold;
                      color: #000000;
                      margin-bottom: 21px;
                  }
                  .option_title_img{
                      display: block;
                      width: 100%;
                      margin-top: 24px;
                      background-color: #2FB8FF;
                  }
                  .option_must{
                      color: #2FB8FF;
                  }
              }
              .reward_input{
                  width: 536px;
                  height: 54px;
                  border-radius: 1.6vw;
                  outline: none;
                  padding-left: 22px;
                  border: 1px solid #2CA2E6;
                }
                ::-webkit-input-placeholder{
                  font-size: 24px;
                  color:#B2B1B1;
                }
                .option_radio{
                    padding-left: 0;
                    width: 100%;
                    .option_text{
                        font-size: 24px;
                        display: flex;
                        justify-content: center;
                        align-self: center;
                        .radio_input{
                            margin-right: 25px;
                         }
                         .radio_text_active{
                             color: #2FB8FF;
                         }

                         .radio_text{
                             line-height: 36px;
                             cursor: pointer;
                          }
                     }
                    // 只有文本
                    .option_radio_block{
                        // margin-top: 10px;
                        display: flex;
                        justify-content: start;
                        align-items: center;
                    }
                     // 存在图片 
                     .option_radio_img{
                        width: 50%;
                        display: inline-block;
                        text-align: center;
                        .option_img{
                            width: 100%;
                            width: 250px;
                        }
                      } 
                  }
                .option_checkbox_wrap{
                    .option_checkbox{
                        width: 100%;
                        // 只有文本
                        .checkbox_block_choose{
                            font-size: 24px;
                            display: flex;
                                    align-items: center;
                                    justify-content: flex-start;
                            .checkbox_input{
                                text-align: center;
                                width: 23px;
                                height: auto;
                                margin-left: 24px;
                                margin-right: 25px;
                            }

                            .checkbox_lable{
                              line-height: 36px;
                              cursor: pointer;
                            }
                            .checkbox_lable_active{
                                color: #2FB8FF;
                            }
                        }

                        .checkbox_block{
                            // margin-top: 1vw;
                            width: 100%;
                            display: flex;
                            justify-content: start;
                            align-items: center;
                        }

                        // 存在图片
                        .checkbox_img{
                            // margin-top: 5px;
                            width: 50%;
                            text-align: center;
                            display: inline-block;
                            .checkbox_block_img{
                                width: 100%;
                                width: 250px;
                            }
                            .checkbox_block_choose{
                                font-size: 24px;
                                // width: 100%;
                            }
                        }
                        
                    }
                    .checkbox_input{
                        width: 488px;
                        height: 54px;
                        border-radius: 12px;
                        outline: none;
                        margin-left: 29px;
                        padding-left: 25px;
                        border: 1px solid #2CA2E6;
                    }
                }  
                
                .suggest_input{
                  position: relative;
                  padding-bottom:8px;
                  .suggest_icon{
                    position: absolute;
                    bottom: 7px;
                    right: 24px;
                    z-index: 2;
                    width: 87px;
                  }
                  .suggest_text{
                    resize:none;
                    outline:none;
                    padding: 10px 0 10px 20px;
                    border: 0.5px solid #2CA2E6;
                    width: 552px;
                    // width: 100%;
                    height: 202px;
                    font-size: 24px;
                    border-radius: 12px;
                    box-shadow:2px 2px 0px #D5D5D5;
                  }
                }
            }
            .submitBtn{
              margin: 0 auto;
              width: 205px;
              height: 58px;
              font-size: 34px;
              font-weight: bold;
              color: #fff;
              background-color: #2FB8FF;
              border-radius: 6px;
              text-align: center;
              line-height:58px;
              cursor: pointer;
            }
        }

        .footer_img{
         width: 100vw;
        }  
    }
    .warn_dialog{
        position: fixed;
        top: 50%;
        left: 50%;
        width: 100%;
        z-index: 10;
        transform: translate(-50%, -50%);
        border-radius: 24px;
        background-color: #fff;
        border: 1px solid black;
        height: 448px;
        width: 663px;
        img{
            margin: 3px 0 0 3px;
            width: 379px;
            margin-bottom: 24px;
        }
        p{
            text-align: center;
            margin: 0 auto;
            width: 280px;
            font-size: 34px;
            margin-bottom: 78px;
        }
        .sure{
            margin: 0 auto;
            width: 205px;
            height: 58px;
            font-size: 34px;
            font-weight: bold;
            color: #fff;
            background-color: #2FB8FF;
            border-radius: 12px;
            text-align: center;
            line-height: 58px;
        }
    }
}

input[type="radio"]+label::before {
    content: " ";
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #999999;
    margin-right: 25px;
    box-sizing: border-box;
    margin-top: -0.1rem;
}

input[type="radio"]:checked+label::before {
    background-color: #2FB8FF;
    background-clip: content-box;
    border: 1px solid #000000;
    padding: 0.1rem;
    box-sizing: border-box;
}
input[type="radio"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
}


input[type="checkbox"]+label::before {
    content: " ";
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #999999;
    // margin-left: 24px;
    margin-right: 25px;
    box-sizing: border-box;
    margin-top: -0.1rem;
}

input[type="checkbox"]:checked+label::before {
    background-color: #2FB8FF;
    background-clip: content-box;
    border: 1px solid #000000;
    padding: 0.1rem;
    box-sizing: border-box;
}
input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
}

.finish_wrap{
    height: 100vh;
    max-width: 750px;
    margin: 0 auto;
    background-color: #2FB8FF;
    background-image: url("../../assets/header.png"), url("../../assets/footer.png");
    background-position: center top,center bottom;
    background-repeat:no-repeat,no-repeat;
    background-size: 100%, 100%;
    overflow: scroll;
    .center_content{
        height: 100vh;
        display:flex;
        justify-content:center;
        align-items:center;
        .content{
            .finish_icon{
                width: 663px;
                height: 206px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-radius: 20px;
                background-color: #fff;
                margin-bottom: 12px;
                img{
                    width: 65px;
                    height: 75px;
                    margin-bottom: 26px;
                }
                p{
                    font-size: 30px;
                    color: #2FB8FF;
                    font-weight: 500;
                    margin: 0;
                }
            }
            .more_type{
                width: 663px;
                height: 402px;
                border-radius: 20px;
                background-color: #fff;
                padding:20px 74px 5px 75px ;
                display: flex;
                justify-content: center;
                align-items: center;
                box-sizing: border-box;
                img{
                    width: 60px;
                    height: 80px;
                }
                .more_app{
                    text-align: center;
                    img{
                        margin-right:122px;
                        margin-bottom: 39px
                    }
                    img:nth-child(3n){
                        margin-right:0;
                    }
                    img:nth-child(5){
                        margin-right:0;
                    }
                }
                .more_line01{
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    margin-bottom: 19px;
                }
                .more_line02{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img:nth-child(1) {
                        margin-right: 102px;
                    }

                }
            }
        }


    } 
}





@media (min-width: 375px) and (max-width: 750px) {
    input[type="radio"]+label::before {
        content: " ";
        display: inline-block;
        vertical-align: middle;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        border: 1px solid #999999;
        margin-right: 3.2vw;
        box-sizing: border-box;
        margin-top: -0.1rem;
    }

    input[type="checkbox"]+label::before {
        content: " ";
        display: inline-block;
        vertical-align: middle;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        border: 1px solid #999999;
        // margin-left: 3vw;
        margin-right: 3.2vw;
        box-sizing: border-box;
        margin-top: -0.1rem;
    }
    .body_box{
        height: 100vh;
        overflow: auto;
        .wrap{
            min-height: 100vh;
            background-color: #2FB8FF;
            background-image: url("../../assets/header.png"), url("../../assets/footer.png");
            background-position: center top,center bottom;
            background-repeat:no-repeat,no-repeat;
            background-size: 100%, 100%;
            position: relative;
            .center_content{
                .content{
                    margin: 31.6vw auto 40.6vw auto;
                    width: 82.4vw;
                    border-radius: 3.2vw;
                    border:  1px solid black;
                    background-color: #fff;
                    padding: 5.3vw 5.3vw 10.8vw 5.3vw;
                    .title_desc{
                      display: flex;
                      justify-content: center;
                      align-items: start;
                      margin-bottom: 5.4vw;
                      img{
                          width: 3.2vw;
                          height: 4.9vw;
                          margin-right: 2.5vw;
                          margin-top: 1.5vw;
                      }
                      .title{
                          display: inline-block;
                          font-size: 5.2vw;
                          margin: 0;
                          font-weight: bold;
                      }
                    }
                    .desc{
                      color: #2FB8FF;
                      font-size: 3.3vw;
                      font-weight: 600;
                      padding-bottom: 5.2vw;
                      margin-bottom: 5.4vw;
                      border-bottom:  0.5px solid #D5D5D5;
                     }
                    .option_list{
                      .option_item{
                        //   border-bottom: 1px solid #D5D5D5;
                          padding-bottom: 5.2vw;
                          margin-bottom: 3.5vw;
                          .option_title{
                              font-size: 3.7vw;
                              margin-bottom: 2.8vw;
                          }
                          .option_title_img{
                              margin-top: 1.8vw;
                          }
                          .option_must{
                              color: #2FB8FF;
                          }
                      }
                      .reward_input{
                          width: 71.4vw;
                          height: 7.2vw;
                          border-radius: 1.6vw;
                          outline: none;
                          padding-left: 2.6vw;
                          border: 1px solid #2CA2E6;
                        }
                        ::-webkit-input-placeholder{
                          font-size: 3.2vw;
                          color:#B2B1B1;
                        }
                        .option_radio{
                            padding-left: 0;
                            width: 100%;
                            .option_text{
                                font-size: 3.2vw;
                                display: flex;
                                justify-content: center;
                                align-self: center;
                                .radio_input{
                                   margin-right: 3.3vw;
                                }
                                .radio_text_active{
                                    color: #2FB8FF;
                                }
                                .radio_text{
                             line-height: 5vw;
                          }
                             }
                            // 只有文本
                            .option_radio_block{
                                margin-top: 1vw;
                                width: 100%;
                            }
                             // 存在图片 
                             .option_radio_img{
                                margin-top: 1vw;
                                width: 50%;
                                display: inline-block;
                                text-align: center;
                                .option_img{
                                    width: 33.3vw;
                                }
                              } 
                          }
                        .option_checkbox_wrap{
                            .option_checkbox{
                                width: 100%;
                                .checkbox_block_choose{
                                    font-size: 3.2vw;
                                    .checkbox_input{
                                        text-align: center;
                                        width: 3.5vw;
                                        height: auto;
                                        margin-left: 3vw;
                                        margin-right: 3.3vw;
                                    }
                                    .checkbox_lable{
                              line-height: 5vw;
                            }
                                    .checkbox_lable_active{
                                        color: #2FB8FF;
                                    }
                                }

                                // 只有文本
                                .checkbox_block{
                                    margin-top: 1vw;
                                    width: 100%;
                                    display: flex;
                                    justify-content: start;
                                    align-items: center;
                                }
                                
                                // 存在图片
                                .checkbox_img{
                                    margin-top: 1vw;
                                    width: 50%;
                                    text-align: center;
                                    display: inline-block;
                                    .checkbox_block_img{
                                        width: 100%;
                                        width: 33.3vw;
                                    }
                                }
                                
                            }
                            .checkbox_input{
                                width: 80%;
                                height: 7.2vw;
                                border-radius: 1.6vw;
                                outline: none;
                                padding-left: 2.6vw;
                                border: 1px solid #2CA2E6;
                            }
                            
                        }  
                        
                        .suggest_input{
                          position: relative;
                          padding-bottom:8px;
                          .suggest_icon{
                            position: absolute;
                            bottom: 2.5vw;
                            right: 2.6vw;
                            z-index: 2;
                            width: 11.6vw;
                          }
                          .suggest_text{
                            resize:none;
                            outline:none;
                            border: 0.5px solid #2CA2E6;
                            // width: 100%;
                            padding: 2vw 0 2vw 3vw;
                            border: 0.5px solid #2CA2E6;
                            width: 68.46vw;
                            height: 26.9vw;
                            font-size: 3.2vw;
                            border-radius: 12px;
                            box-shadow:2px 2px 0px #D5D5D5;
                              // background-color: aquamarine;
                          }
                        }
                    }
                    .submitBtn{
                      margin: 0 auto;
                      width: 27.3vw;
                      height: 7.7vw;
                      font-size: 4vw;
                      font-weight: bold;
                      color: #fff;
                      background-color: #2FB8FF;
                      border-radius: 6px;
                      text-align: center;
                      line-height: 7.7vw;
                    }
                }
    
                .footer_img{
                width: 100vw;
                }  
            }
            .warn_dialog{
                position: fixed;
                top: 50%;
                left: 50%;
                width: 100%;
                z-index: 10;
                transform: translate(-50%, -50%);
                border-radius: 3.2vw;
                background-color: #fff;
                border: 1px solid black;
                height: 59.7vw;
                width: 88vw;
                img{
                    margin: 3px 0 0 3px;
                    width: 50.5vw;
                    margin-bottom: 3.2vw;
                }
                p{  
                    font-size: 3.8vw;
                    text-align: center;
                    margin-bottom: 10.4vw;
                }
                .sure{
                    margin: 0 auto;
                    width: 27.3vw;
                    height: 7.7vw;
                    font-size: 4vw;
                    font-weight: bold;
                    color: #fff;
                    background-color: #2FB8FF;
                    border-radius: 6px;
                    text-align: center;
                    line-height: 7.7vw;
                }
            }
        }
    }

    .body_box_noscroll{
        overflow: hidden;
    }

    .finish_wrap{
        height: 100vh;
        margin: 0 auto;
        background-color: #2FB8FF;
        background-image: url("../../assets/header.png"), url("../../assets/footer.png");
        background-position: center top,center bottom;
        background-repeat:no-repeat,no-repeat;
        background-size: 100%, 100%;
        overflow: scroll;
        .center_content{
            height: 100vh;
            display:flex;
            justify-content:center;
            align-items:center;
            .content{
                // height: 63vh;
                .finish_icon{
                  width: 88.4vw;
                  height: 72.8vw;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  border-radius: 3.2vw;
                  background-color: #fff;
                  margin: 0 auto 8px auto;
                  img{
                      width: 21.7vw;
                      height: 23.4vw;
                      margin-bottom: 12.8vw;
                  }
                  p{
                      font-size: 4.6vw;
                      color: #2FB8FF;
                      font-weight: 500;
                  }
                }
                .more_type{
                  width: 88.4vw;
                  height: 53.6vw;
                  border-radius: 3.2vw;
                  background-color: #fff;
                  padding: 6.26vw 10vw 0.4vw 10vw;
                  margin: 0 auto;
                  
                    img{
                        width: 13.8vw;
                        height: 17.8vw;
                    }
                    .more_app{
                        text-align: center;
                        img{
                            margin-right:6.6vw;
                            margin-bottom: 5.2vw
                        }
                        img:nth-child(3n){
                            margin-right:0;
                            margin-bottom: 5.2vw
                        }
                    }
                    .more_line01{
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        margin-bottom: 5.2vw;
                    }
                    .more_line02{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        img:nth-child(1) {
                            margin-right: 13.6vw;
                        }

                    }
                }
            }
        }
    }
    
}
</style>
